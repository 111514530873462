import apiClient from '@/axios';

export const vacances = {
    state: () => ({
        vacances: [],
        singleVacancy: [],
        locations: [],
        workDate: [],
        workType: [],
        cityList: [],
        errors: []
    }),

    actions: {

        async fetchVacances({ commit }:any) {
            try {
                const response = await apiClient.get('/vacances');

                commit('setVacances', response)
                //commit('setLocation', response)
         //       commit('setVacancysError', null)

            }  catch (error) {
              //  commit('setVacancysError', error)
            }        
              
          },
    },

    mutations: {
        setVacances(state:any, data:any) {
            if(!data) {
                return state.vacances = []
            }
            
            state.vacances = data.data.vacances_list

            const city = data.data.city_list.filter((item:any) => !item.parent)
            state.cityList = city.map((item:any) => item.name)

            state.workDate = data.data.all_work_date
            state.workType = data.data.type_vacancy

        }
    },

    getters: {
        getVacances(state:any) {
            return state.vacances
        },
        getVacancesWorkDate(state:any) {
            return state.workDate
        },
        getVacancesWorkType(state:any) {
            return state.workType
        },
        getVacancesCityList(state:any) {
            return state.cityList
        },
    }
}
<template lang="">
    <article class="vacancy row container">
        <div class="col">
            <section class="header-section">
                <h1 class="title">Керуючий магазином</h1>
                <div class="row base-info">
                    <div class="col">
                        <p class="title">Місто</p>
                        <p class="text">Львів</p>
                    </div>
                    <div class="col">
                        <p class="title">Зайнятість</p>
                        <p class="text">Повна. Також готові взяти студента.</p>
                    </div>
                </div>
                <div class="description">
                    <p>Друже, а ти не «засидівся» на своєму робочому місці? Може вже настав час підняти планку і повірити в себе? Приходи до нас на співбесіду! Близенько потрібен Керуючий магазином з бажанням розвивати та розвиватись.</p>
                    <h3>Вибирай найближчу локацію до себе і телефонуй нам:</h3>
                    <p>
                        - ул. Чорновола, 67ж <br> - вул. Дзиндри, 1 <br> - вул. Зарицьких, 5 <br> - вул. Шафарика, 1
                    </p>
                    <h3>
                        На своїй посаді ти матимеш такі обов’язки:
                    </h3>
                    <p>
                        - забезпечуватимеш високий рівень обслуговування покупців <br> - управлятимеш персоналом магазину та формуватимеш команду найближчих <br> - організовуватимеш та координуватимеш торгові процеси магазину <br> - робитимеш все для досягнення показників роботи магазину
                    </p>
                    <h3>
                        Що ми пропонуємо тобі?
                    </h3>
                    <p>
                        Найзручнішу локацію роботи. Працюватимеш біля свого дому і зможеш «в тапочках» приходити на роботу. Найближчий колектив. Ти сам зможеш сформувати свою команду найближчих і ви разом організуєте найзручніші умови роботи. Зручний графік роботи. Наша зайнятість дозволяє підтримувати якісний баланс робота-життя і ти багато встигатимеш не лише у вихідні, але й у будні. Якісний соцпакет: 24 дні щорічної відпустки, оплата лікарняного, офіційне працевлаштування. Стабільну і вчасну заробітну плату. Наш «календар винагороди» ніколи не збивається і ти двічі на місяць отримуватимеш заробітну плату. Якісний розвиток та кар’єрне зростання. Ми навчимо тебе всього, що вміємо і будемо щасливі, якщо вже за кілька місяців ти зростеш до посади Керівника групи магазинів.
                    </p>
                </div>
            </section>
        </div>
        <div class="col sitebar-wrap">
            <aside class="sitebar">
                <div class="contacts">
                    <p class="title">
                        Контактна особа
                    </p>
                    <p class="name">
                        Христина Головата
                    </p>
                    <div class="number-wrap">
                        <transition name="fade-show">
                            <button class="btn" v-if="!showPhone" @click=" showPhone = !showPhone">ПОКАЗАТИ НОМЕР</button>
                        </transition>
                        <transition name="fade-show">
                            <a href="tel:+0 800 201 800" class="phone" v-if="showPhone">0 800 201 800</a>
                        </transition>
                    </div>
                </div>

                <div class="form-wrap">
                    <p class="title">
                        Відгунутись на вакансію
                    </p>
                    <form action="">
                        <p><input type="text" name="name" id="name" placeholder="Імʼя"></p>
                        <p><input type="tel" name="phone" id="phone" placeholder="Телефон"></p>
                        <p><input type="email" name="email" id="email" placeholder="E-mail"></p>
                        <p><textarea name="about" id="about" rows="4" placeholder="Пару слів про себе"></textarea></p>
                        <p class="file-upload"> 
                            <label>
                              <input type="file" id="file" name="file" accept="jpg, jpeg, pdf" :value="file"  @input="addFile">
                              <span class="filebtn">ПРИКРІПИТИ РЕЗЮМЕ</span>
                            </label>
                            <span id="filename" class="filename">{{fileName}}
                         </span></p>
                        <button type="submit" class="btn">НАДІСЛАТИ</button>
                        <p class="text">
                            Надсилаючи цю форму, я даю згоду на обробку моїх персональних даних відповідно до Політики конфіденційності.
                        </p>
                    </form>
                </div>
            </aside>
        </div>
    </article>
</template>
<script>
export default {
    data() {
        return {
            showPhone: false,
            file: null,
            fileName: ''
        }
    },
    methods: {
        addFile(event) {
            const file = event.target.files
            if(!file.length) {
                this.fileName = ''
                return
            }


            this.fileName = file[0].name
        }
    },
}
</script>
<style lang="scss">
    @import "../../assets/scss/single-work-in.scss";
</style>
<template>
  <div v-if="page">
    <section class="header-section">
        <div class="container">
        <div class="row page-header-wrap">
            <div class="col">
               <h1 class="title">{{page.title?.rendered}}</h1>
            </div>
            <div class="col">
                <p class="sub-title" v-if="pageFields && pageFields.subtitle">{{pageFields.subtitle}}</p> 
            </div>
            <div class="col color-text-wrap">
                <p class="color-text" v-if="pageFields && pageFields.action_text">{{pageFields.action_text}}</p>
            </div>
        </div>
    </div>
      <div class="video-container">
        <video-background
        class="myVideo"
          v-if="
            pageFields &&
            pageFields.video &&
            pageFields.video.video_bg &&
            pageFields.video.video_bg.url
          "
          :src="pageFields.video.video_bg.url"
          :poster="videoImg(pageFields.video.img_video_bg)"
          style="max-height: 900px; height: 100vh"
          overlay="linear-gradient(45deg,#2a4ae430,#fb949e6b)"
          ref="videoRefs"
          @loaded="handleVideoLoaded()"
        >
        </video-background>
      </div>
    </section>
    <section class="company-statisticks-section">
        <div class="container">
            <div class="row">
                <div class="col">
                    <h2 class="title">{{pageFields.title_counter}}</h2>
                    <p class="text">{{pageFields.tekst_count}}</p>
                </div>
                <div class="col">
                    <compayCounter/>
                </div>
            </div>
        </div>
    </section>

    <section class="about-content-section" v-if="pageFields && pageFields.about_text_repeat">
        <div class="container">
            <div class="row about-content"
            v-for="(item, index) in pageFields.about_text_repeat"
            :key="index"
            >
                <div class="col content" v-if="item.text" v-html="item.text"></div>
                <div class="col image" v-if="item.Image || item.video">
                    <picture class="img"  v-if="!item.show_video[0]">
                        <source :srcset="item.Image.sizes.medium_large" media="(max-width: 520px)"  />
                        <img :src="item.Image.sizes.large" :alt="item.Image.title" />
                    </picture>
                    <VideoBg
                      v-if="item.show_video[0] && item.video"
                      :videoSrc = "item.video.url"
                      :posterLink = "item.video.icon"
                      :isAnimate=false
                      maxHeight="540px"
                      class="video"
                    />
                </div>
            </div>
        </div>
    </section>
    <section class="background-section" v-if="pageFields && pageFields.image_block_group">
        <picture class="bg-image">
            <source :srcset="pageFields.image_block_group.image_bg.sizes.medium_large" media="(max-width: 520px)"  />
            <source :srcset="pageFields.image_block_group.image_bg.sizes.large" media="(max-width: 960px)"  />
            <img :src="pageFields.image_block_group.image_bg.sizes['2048x2048']" :alt="pageFields.image_block_group.image_bg.title" />
        </picture>
        <div class="container">
            <h2 class="title">{{pageFields.image_block_group.title_map}}</h2>
            <p class="text">{{pageFields.image_block_group.text}}</p>
            <p class="color-text">{{pageFields.image_block_group.action_text}}</p>
        </div>
    </section>
  </div>
</template>

<script>
import { ref, onMounted, onUpdated } from "vue";
import { mapGetters, mapActions } from "vuex";
import VideoBackground from "vue-responsive-video-background-player";
import compayCounter from "../global/compayCounter.vue";
import { gsapMixin } from '../../mixins/gsapMixin';
import VideoBg from "../global/VideoBg.vue";

export default {
  mixins: [gsapMixin],
  components: {
    "video-background": VideoBackground,
    compayCounter,
    VideoBg
  },
  setup() {
    const videoRefs = ref(null);

    const handleVideoLoaded = () => {
      const videoPlayer = videoRefs.value;
      if (videoPlayer) {
        videoPlayer.playVideo(); 
      }
    };

    onUpdated(() => handleVideoLoaded())

    return {
      videoRefs,
      handleVideoLoaded
    };
  },
  data() {
    return {
      page: null,
      pageFields: null,
    };
  },
  computed: {
    ...mapGetters(["getPage", "getSettings"]),
  },
  mounted() {
    const pageSlug = this.$route.params?.pageSlug;
    this.loadPageContent(pageSlug);

    this.$nextTick(() => {
        const videoPlayer = this.$refs.videoPlayer;
        if (videoPlayer) {
          const videoElement = videoPlayer.$refs.video;
          if (videoElement) {
            videoElement.muted = true;
          }
        }
      });
  },
  methods: {
    ...mapActions(["fetchPage"]),
    async loadPageContent(slug) {

       if(this.getPage[0]) {
          this.page = this.getPage[0];
          this.pageFields = this.page.acf_fields;
          return
        }

      await this.fetchPage(slug).then(() => {
        this.page = this.getPage[0];
        this.pageFields = this.page.acf_fields;
      });
    },
    videoImg(imgArray) {
      if (!imgArray) return "";
      return imgArray.sizes.large;
    },
  },
};
</script>

<style lang="scss">
    @import '../../assets/scss/about-page.scss';
</style>

import { createStore } from 'vuex'

import { posts } from './modules/posts'
import { singePost } from './modules/singePost'
import { page } from './modules/page'
import { global } from './modules/global'
import { shops } from './modules/shops'
import { vacances } from './modules/vacances'

export default createStore({
  modules: {
    global,
    posts,
    page,
    singePost,
    shops,
    vacances
  },
});

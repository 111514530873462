import { createRouter, createWebHistory, RouteRecordRaw, Router, RouteComponent } from 'vue-router';

import HomeView from '../views/HomeView.vue';
import SinglePostView from '../views/SinglePostView.vue';
import SingleShopView from '../views/SingleShopView.vue';
import PageView from '../views/PageView.vue';
import WorkInSingle from '../components/pages/WorkInSingle.vue';
import NotFound from '../components/NotFound.vue';
import PageList from '../../pages-list.json';

const domainElement = document.head?.querySelector<HTMLMetaElement>('[property="og:url"]');
const domain = domainElement ? domainElement.content : '';

const siteUrl = domain ? new URL(domain) : null;
const path = siteUrl?.pathname || '';

const pagePath = (template:string, basic:string, type:string) => {
  if(!template && !PageList) return basic
  
  const pathArrray = PageList?.find(item => item.template == template )
  const path = pathArrray && pathArrray.slug ? '/' + pathArrray.slug : '/' + basic
  const slug = pathArrray && pathArrray.slug ? pathArrray.slug : ''

  return type == 'slug' ? slug : path
}

const routes: Array<RouteRecordRaw> = [
  {
    path: path,
    name: 'home',
    component: HomeView,
  },
  {
    path: path+'/post/:postSlug',
    name: 'post',
    component: SinglePostView,
  },
  {
    path: path+'/shops/:shopSlug',
    name: 'shop',
    component: SingleShopView,
  },
  {
    path: path+'/:pageSlug',
    name: 'page',
    component: PageView,
    meta: {
      slug: pagePath('template_map.php', 'shop-map', 'slug'),
    }
  },
  {
    path: path+'/vancncy',
    name: 'vancncy',
    component: WorkInSingle,
  },
  // {
  //   path: pagePath('template_map.php', 'shop-map', ''),
  //   name: 'shopMap',
  //   component: () => import(/* webpackChunkName: "GoogleMapMain" */ '@/components/pages/GoogleMapMain.vue'),
  //   meta: {
  //     slug: pagePath('template_map.php', 'shop-map', 'slug'),
  //   }
  // },
  {
    path: '/404',
    name: '404',
    component: NotFound,
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
  scrollBehavior() {
    return new Promise((resolve) => {
      setTimeout(() => {
        resolve({ left: 0, top: 0 });
      }, 500);
    });
  },
});

export default router;



router.afterEach((to) => {
  const body = document.querySelector('body');
  const slug = (to.params.postSlug as string) || (to.params.pageSlug as string);

  if (body && slug) {
    body.classList.add(`vue--page--${slug}`);
  }
});
